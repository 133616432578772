import { getAction,getByIdAction,postAction,putAction,getByTypeAction,deleteByIdAction } from '../common/request'
const baseURL = 'xun/work/xunFund'


const getFundList = (params) => getAction(baseURL+"/list", params);

const getFaMoneyApi=(id)=>getByIdAction(baseURL+"/getFaMoney",id);

const selectFundById=(id)=>getByIdAction(baseURL+"/selectFundById",id);

const dealFundMoney=(params)=>postAction(baseURL+"/dealFundMoney",params);

const adjustFundMoney=(params)=>postAction(baseURL+"/adjustFundMoney",params);

const  editFund=(params)=>putAction(baseURL+"/editFund",params);

const addFund=(params)=>postAction(baseURL+"/addFund",params);

const updateIncomeMoney=(params)=>putAction(baseURL+"/updateIncomeMoney",params);

const selectFundLogs=(params)=>getByIdAction(baseURL+"/selectFundLogs",params);

const selectFundList=(params)=>getByTypeAction(baseURL+'/selectFundList',params);

const delectById=(params)=>deleteByIdAction(baseURL+'/delectById',params);

const listByPrams = (params) => getAction(baseURL+"/listByPrams", params);

const batchUpdateFund=(params)=>postAction(baseURL+"/batchUpdateFund",params);
const summaryBankMoneyApi=(params)=>getAction(baseURL+"/summaryBankMoney",params);


export {
    getFundList,
    selectFundById,
    dealFundMoney,
    adjustFundMoney,
    editFund,
    addFund,
    updateIncomeMoney,
    selectFundLogs,
    selectFundList,
    delectById,
    listByPrams,
    batchUpdateFund,
    getFaMoneyApi,
    summaryBankMoneyApi
}
